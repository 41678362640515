<template>
    <div class="lg:relative">
        <div class="lg:m-2 md:m-6 mx-3 mt-3">
            <!-- <div class="text-center my-8">
                <form @submit.prevent="search">
                    <input type="text" name="" id="" placeholder="SEARCH FOR ARTISTS..." 
                        class="placeholder-black font-bold text-black w-full text-md p-2 md:p-4 text-center
                        bg-gray-100 rounded-xl focus:outline-none"
                        v-model="searchStr"
                    >
                </form>
            </div> -->
            <router-link class="grid grid-cols-2 mt-3 gap-3 users items-center" v-for="(artist,index) in artists.data" :key="index" :to="{ name: 'ProfileWithSlug' , params: { slug: artist.slug } }">
                <div class="items-center">
                    <img src="../../assets/image/default.png" alt="" class="rounded-full responsive-image profile-image-list float-left" v-if="artist.image == null || artist.image == '' ">
                    <img onerror="this.onerror=null;this.src=document.getElementById('defaultImage').src;" :src="media_base_url+artist.image" alt="" class="rounded-full responsive-image profile-image-list float-left" v-else>
                    <p class="pl-2 artist-list-name text-sm truncate mt-1">
                        {{index + 1}}. {{ artist.member_name }}
                    </p>
                </div>
                <div class="text-right w-full text-sm text-white">
                    <p>{{ artist.vote_count }} <span v-if="artist.vote_count == 1">Vote</span><span v-else>Votes</span> </p>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import API from "../../api/http";

export default {
    name: 'TopRappers',
    data() {
        return {
            media_base_url: process.env.VUE_APP_MEDIA_BASE_URL,
            // artists: [],
            getTopArtistUrl: 'top-artists',
            artistCount: null,
        }
    },
    watch:{
        $route (newValue){
            if(newValue.name == 'TopRappers'){
                this.topArtist()
            }
        }
    },
    computed:{
        user() {
            return this.$store.getters['User/user'];
        },
        artists() {
            return this.$store.getters['Artistes/artists'];
        },
    },
    created(){
        // console.log(this.$route.name)
        this.topArtist();
    },
    methods: {
        topArtist(){
            API.get('top-artists?filter=all')
            .then((response)=> {
                this.$store.commit('Artistes/SET_ARTISTS', response.artists)
                // this.artists = response.artists;
            })
        },
        search(){
            if(this.search != ''){
                this.$router.push({name:'Search',params:{'string':this.searchStr}})
            }
        },
        removeUser(e){
            e,
            this.topArtist();
        }
    }
}
</script>
